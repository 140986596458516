* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  font-family: Roboto, sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}

.general-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #000;
  overflow: hidden;
}
