/* src/TextEditor.css */

/*body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}*/

.editor-container {
    width: 100%;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden;
}

.editor-toolbar {
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
    padding: 5px;
    display: flex;
    justify-content: space-around;
}

.toolbar-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
    color: #333;
    border-radius: 3px;
    transition: background-color 0.3s ease;
}

.disabled-button {
    pointer-events: none; /* Desabilita os cliques */
    opacity: 0.5; /* Torna o bot�o visualmente desabilitado */
    cursor: not-allowed; /* Muda o cursor para indicar que n�o est� clic�vel */
}

.toolbar-button:hover {
    background-color: #ddd;
}

.editor-content {
    padding: 10px;
    min-height: 300px;
    overflow-y: auto;
}

.editor-content:focus {
    outline: none;
}


